import {ILink} from 'components/page/recreationHouses/interfaces/ILink';
import { ERecreationHousesKey } from 'components/page/recreationHouses/enum/ERecreationHousesKey';

export const RECREATION_HOUSES_LOCATIONS: Record<ERecreationHousesKey, ILink> = {
    [ERecreationHousesKey.BRATISLAVA]: {title: 'Bratislava', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-bratislava-predaj/'},
    [ERecreationHousesKey.HORNA_NITRA]: {title: 'Horná Nitra', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-horna-nitra-predaj/'},
    [ERecreationHousesKey.ZAHORIE]: {title: 'Záhorie', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-zahorie-predaj/'},
    [ERecreationHousesKey.DOLNA_NITRA]: {title: 'Dolná Nitra', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-dolna-nitra-predaj/'},
    [ERecreationHousesKey.PODUNAJSKO]: {title: 'Podunajsko', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-podunajsko-predaj/'},
    [ERecreationHousesKey.TEKOV]: {title: 'Tekov', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-tekov-predaj'},
    [ERecreationHousesKey.HORNE_POVAZIE]: {title: 'Horné Považie', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-horne-povazie-predaj/'},
    [ERecreationHousesKey.KYSUCE]: {title: 'Kysuce', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-kysuce-predaj/'},
    [ERecreationHousesKey.STREDNE_POVAZIE]: {title: 'Stredné Považie',path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-stredne-povazie-predaj/'},
    [ERecreationHousesKey.TURIEC]: {title: 'Turiec', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-turiec-predaj/'},
    [ERecreationHousesKey.DOLNE_POVAZIE]: {title: 'Dolné Považie', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-dolne-povazie-predaj/'},
    [ERecreationHousesKey.HONT]: {title: 'Hont', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-hont-predaj/'},
    [ERecreationHousesKey.ORAVA]: {title: 'Orava', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-orava-predaj/'},
    [ERecreationHousesKey.LIPTOV]: {title: 'Liptov', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-liptov-predaj/'},
    [ERecreationHousesKey.HOREHRONIE]: {title: 'Horehronie', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-horehronie-predaj/'},
    [ERecreationHousesKey.PODPOLANIE]: {title: 'Podpoľanie', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-podpolanie-predaj/'},
    [ERecreationHousesKey.POIPLIE]: {title: 'Poiplie', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-poiplie-predaj/'},
    [ERecreationHousesKey.ZAMAGURIE]: {title: 'Zamagurie', path: 'https://www.nehnutelnosti.sk/okres-stara-lubovna/rekreacne-domy/predaj/'},
    [ERecreationHousesKey.TATRY]: {title: 'Tatry', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-tatry-predaj/'},
    [ERecreationHousesKey.SPIS]: {title: 'Spiš', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-spis-predaj/'},
    [ERecreationHousesKey.SARIS]: {title: 'Šariš', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-saris-predaj/'},
    [ERecreationHousesKey.ABOV]: {title: 'Abov', path: 'https://www.nehnutelnosti.sk/kosice/rekreacne-domy/predaj/'},
    [ERecreationHousesKey.HORNY_ZEMPLIN]: {title: 'Horný Zemplín', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-horny-zemplin-predaj/'},
    [ERecreationHousesKey.DOLNY_ZEMPLIN]: {title: 'Dolný Zemplín', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-dolny-zemplin-predaj/'},
    [ERecreationHousesKey.GEMER]: {title: 'Gemer', path: 'https://www.nehnutelnosti.sk/zoznam-realit/chaty-region-gemer-predaj/'},
};
