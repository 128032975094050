import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Box} from '@mui/material';
import TreeIcon from 'components/page/recreationHouses/svg/tree.svg';
import FarmIcon from 'components/page/recreationHouses/svg/farm.svg';
import LandIcon from 'components/page/recreationHouses/svg/land.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

export const Headline: FC = () => {
    return <Box display="flex" justifyContent="center" flexDirection="column" textAlign="center" width="100%">

        <Box alignItems="flex-end" gap={1} mb={2}>
            <Svg component={TreeIcon} width={32} height={32}/>
            <Svg component={FarmIcon} width={48} height={48}/>
            <Svg component={LandIcon} width={32} height={32}/>
        </Box>

        <Text mobileVariant='h4' variant={'h1'} component="h1" semibold>
            Objavte vysnívanú chatu <br/> na oddych či dovolenku
        </Text>

    </Box>;
};
