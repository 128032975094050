export enum ERecreationHousesKey {
    'BRATISLAVA' = 'BRATISLAVA',
    'ZAHORIE' = 'ZAHORIE',
    'PODUNAJSKO' = 'PODUNAJSKO',
    'HORNE_POVAZIE' = 'HORNE_POVAZIE',
    'STREDNE_POVAZIE' = 'STREDNE_POVAZIE',
    'DOLNE_POVAZIE' = 'DOLNE_POVAZIE',
    'HORNA_NITRA' = 'HORNA_NITRA',
    'DOLNA_NITRA' = 'DOLNA_NITRA',
    'TEKOV' = 'TEKOV',
    'KYSUCE' = 'KYSUCE',
    'TURIEC' = 'TURIEC',
    'HONT' = 'HONT',
    'ORAVA' = 'ORAVA',
    'LIPTOV' = 'LIPTOV',
    'HOREHRONIE' = 'HOREHRONIE',
    'PODPOLANIE' = 'PODPOLANIE',
    'POIPLIE' = 'POIPLIE',
    'ZAMAGURIE' = 'ZAMAGURIE',
    'TATRY' = 'TATRY',
    'SPIS' = 'SPIS',
    'SARIS' = 'SARIS',
    'ABOV' = 'ABOV',
    'HORNY_ZEMPLIN' = 'HORNY_ZEMPLIN',
    'DOLNY_ZEMPLIN' = 'DOLNY_ZEMPLIN',
    'GEMER' = 'GEMER',
}
