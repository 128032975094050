'use client';

import {FC} from 'react';
import {Container, Box} from '@mui/material';
import Map from 'components/page/recreationHouses/components/map/Map';
import {Headline} from 'components/page/recreationHouses/components/Headline';
import {SeoLinksList} from 'components/page/recreationHouses/components/seoLinks/SeoLinksList';

const Main: FC = () => {
    return <>
        <Container maxWidth="lg" sx={{paddingTop: {xs: 5, md: 10.5,}}}>
            <Box mb={2}>
                <Headline/>
            </Box>
        </Container>

        <Box textAlign="center">
            <Map/>
        </Box>

        <Container maxWidth="lg">
            <SeoLinksList/>
        </Container>
    </>;
};

export default Main;
