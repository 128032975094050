import {FC} from 'react';
import {Grid} from '@mui/material';
import {Item} from 'components/page/recreationHouses/components/seoLinks/Item';
import {RECREATION_HOUSES_LOCATIONS} from 'components/page/recreationHouses/constants';

export const SeoLinksList: FC = () => {
    return <Grid container spacing={1} marginBottom={4}>

        {Object.values(RECREATION_HOUSES_LOCATIONS).map(location => (
            <Grid item md={3} xs={6} key={`recreation-houses-${location.title}`}>
                <Item path={location.path} title={location.title}/>
            </Grid>
        ))}

    </Grid>;
};
